<template>
    <div class="flex flex-1 flex-col items-center">
        <div class="card w-full" style="max-width: 800px">
            <header class="flex items-center justify-between h-10 border-b border-solid border-gray-200 pb-4 mb-5">
                <h1>{{ $t('client_details.fortnox_integration') }}</h1>
            </header>

            <p class="bg-yellow-400 p-4 mb-4 rounded-lg">
                If this client in Workflows has not yet "custom nr" assigned, system will generate a new one and create this client in Fortnox.
            </p>

            <p class="bg-yellow-400 p-4 rounded-lg">
                If this client in Workflows has already "custom nr", system will check if this client in Fortnox has the same "custom nr" and if not, system will update this client in Fortnox.
            </p>

            <div class="flex justify-end pt-3 mt-10 border-t border-gray-200">
                <el-button type="success" :loading="$wait.is('updating')" @click="update">
                    {{ $t('common.update') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Axios from 'axios';


export default {
    beforeRouteUpdate(to, from, next) {
        this.clientUuid = to.params.uuid;
        next();
    },

    data() {
        return {
            clientUuid: this.$route.params.uuid,
        };
    },

    methods: {
        async update() {
            this.$wait.start('updating');

            try {
                await axios.post(`/api/clients/clients/${this.clientUuid}/fortnox`);
                this.$notify.success({ title: 'Success' });
            } finally {
                this.$wait.end('updating');
            }
        },
    },
};
</script>
